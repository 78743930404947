<template>
  <validation-observer ref="simpleRules">
    <div class="card p-2">
      <!-- Agent Information -->
      <div class="row">
        <div class="col-md-12 mb-1">
          <h3 class="font-weight-bold">
            Agent Information
          </h3>
        </div>
        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="username">
            Username
            <span class="text-danger">★</span>
          </label>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Username"
              rules="required"
            >
              <b-form-input
                v-model="username"
                :state="errors.length > 0 ? false : null"
                placeholder="Username"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div class="col-md-5">
          <label for="Position Type">
            Status
          </label>

          <div
            role="group"
            class="d-flex"
          >
            <button
              id="selectReseller"
              type="button"
              :class="`btn btn-r ${status === 1 ? 'btn-on' : 'btn-off'}`"
              @click="switchStatus(1)"
            >
              Active
            </button>

            <button
              id="selectAgent"
              type="button"
              :class="`btn btn-l ${status === 0 ? 'btn-on' : 'btn-off '}`"
              @click="switchStatus(0)"
            >
              Inactive
            </button>
          </div>
        </div>
        <div class="col-md-1" />

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="First Name">
            First Name
            <span class="text-danger">★</span>
          </label>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                v-model="fristname"
                :state="errors.length > 0 ? false : null"
                placeholder="Name"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div class="col-md-5">
          <label for="Last Name">
            Last Name
            <span class="text-danger">★</span>
          </label>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="lastname"
              rules="required"
            >
              <b-form-input
                v-model="lastname"
                :state="errors.length > 0 ? false : null"
                placeholder="Last Name"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-md-1" />

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="Password">
            Password
            <span class="text-danger">★</span>
          </label>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-form-input
                v-model="password"
                :state="errors.length > 0 ? false : null"
                placeholder="Password"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="col-md-5">
          <label for="Upline">
            Upline
          </label>

          <b-form-group>
            <b-form-input
              v-model="upline"
              size="lg"
              type="text"
            />
          </b-form-group>

        </div>
        <div class="col-md-1" />

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="Position Type">
            Role
          </label>

          <div
            role="group"
            class="d-flex"
          >
            <button
              id="selectReseller"
              type="button"
              :class="`btn btn-r ${role === 0 ? 'btn-on' : 'btn-off'}`"
              @click="switchRole(0)"
            >
              Agent
            </button>

            <button
              id="selectAgent"
              type="button"
              :class="`btn btn-c ${role === 1 ? 'btn-on' : 'btn-off '}`"
              @click="switchRole(1)"
            >
              Master
            </button>

            <button
              id="selectAgent"
              type="button"
              :class="`btn btn-l ${role === 2 ? 'btn-on' : 'btn-off '}`"
              @click="switchRole(2)"
            >
              Company
            </button>
          </div>
        </div>

        <div class="col-md-5">
          <label for="hide">
            Hide
          </label>

          <div
            role="group"
            class="d-flex"
          >
            <button
              id="selectReseller"
              type="button"
              :class="`btn btn-r ${hide === 0 ? 'btn-on' : 'btn-off'}`"
              @click="switchHide(0)"
            >
              Show
            </button>

            <button
              id="selectAgent"
              type="button"
              :class="`btn btn-l ${hide === 1 ? 'btn-on' : 'btn-off '}`"
              @click="switchHide(1)"
            >
              Hide
            </button>
          </div>
        </div>
        <div class="col-md-1" />
      </div>
      <!-- End Agent Information -->

      <!-- Web Name -->
      <div class="row mt-4">
        <div class="col-md-12 mb-1">
          <h3 class="font-weight-bold">
            Web Name
          </h3>
        </div>

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="web_name">
            WEB Name
          </label>

          <b-form-group>
            <b-form-input
              v-model="web_name"
              size="lg"
              placeholder="Web Name"
              type="text"
            />
          </b-form-group>
        </div>
        <div class="col-md-5">
          <label for="amb_name">
            AMB Name
          </label>
          <b-form-group>
            <b-form-input
              v-model="amb_name"
              size="lg"
              placeholder="AMB Name"
              type="text"
            />
          </b-form-group>
        </div>
        <div class="col-md-1" />

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="awc_name">
            AWC Name
          </label>

          <b-form-group>
            <b-form-input
              v-model="awc_name"
              size="lg"
              placeholder="AWC Name"
              type="text"
            />
          </b-form-group>
        </div>

        <div class="col-md-5">
          <label for="sbo_name">
            SBO Name
          </label>

          <b-form-group>
            <b-form-input
              v-model="sbo_name"
              placeholder="SBO Name"
              type="text"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-md-1" />

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="sbo_serverid">SBO Server ID</label>

          <b-form-group>
            <b-form-input
              v-model="sbo_serverid"
              placeholder="SBO Server ID"
              size="lg"
            />
          </b-form-group>
        </div>
      </div>
      <!-- End Web Name -->

      <!-- Group ID -->
      <div class="row mt-4">
        <div class="col-md-12 mb-1">
          <h3 class="font-weight-bold">
            Group ID
          </h3>
        </div>
        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="Power ID">Power ID</label>

          <b-form-group>
            <b-form-input
              v-model="power_id"
              placeholder="Power ID"
              size="lg"
            />
          </b-form-group>
        </div>
        <div class="col-md-5">
          <label for="lotto_id">Lotto ID</label>

          <b-form-group>
            <b-form-input
              v-model="lotto_id"
              placeholder="Lotto ID"
              size="lg"
            />
          </b-form-group>
        </div>

        <div class="col-md-1" />

        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="sbo_id">SBO ID</label>

          <b-form-group>
            <b-form-input
              v-model="sbo_id"
              placeholder="SBO ID"
              size="lg"
            />
          </b-form-group>
        </div>
      </div>
      <!-- End Group ID -->

      <!-- DB Group -->
      <div class="row mt-4">
        <div class="col-md-12 mb-1">
          <h3 class="font-weight-bold">
            DB Group
          </h3>
        </div>
        <div class="col-md-1" />
        <div class="col-md-5">
          <label for="db_group">DB Group</label>

          <b-form-group>
            <b-form-input
              v-model="db_group"
              placeholder="DB Group"
              size="lg"
            />
          </b-form-group>
        </div>
      </div>
      <!-- End DB Group -->

      <!-- Product Royalty Settings -->
      <div class="row mt-4">
        <div class="col-md-12">
          <h3 class="font-weight-bold">
            Product Royalty Settings
          </h3>
        </div>
        <div class="col-md-6 mt-1 border rounded py-1">
          <div class="multi-button">
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'GamesSlot' }"
              @click="SelectDate('GamesSlot')"
            >
              Games Slot
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'LiveCasino' }"
              @click="SelectDate('LiveCasino')"
            >
              Live Casino
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'GameCard' }"
              @click="SelectDate('GameCard')"
            >
              Game Card
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'Treding' }"
              @click="SelectDate('Treding')"
            >
              Treding
            </button>
            <button
              class="btn-gg"
              :class="{ activated: activeBtn === 'Sportbook' }"
              @click="SelectDate('Sportbook')"
            >
              Sportbook
            </button>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th style="width: 2%">
                    No.
                  </th>
                  <th
                    scope="col"
                    style="width: 25%"
                  >
                    Product
                  </th>
                  <th style="width: 35%">
                    Our Percentage
                  </th>
                  <th style="width: 35%">
                    Hold Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                  :class="item.status === 0 ? 'unselectable' : ''"
                >
                  <td>
                    {{ index + 1 }}
                  </td>

                  <td style="text-align: left">
                    <label style="padding: 4px">{{ item.ID }}</label>
                  </td>

                  <td>
                    <b-form-input
                      v-model="item.our_percent"
                      type="number"
                      :placeholder="item.our"
                    />
                  </td>

                  <td>
                    <b-form-input
                      v-model="item.hold_percent"
                      type="number"
                      :placeholder="item.hold"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Product Royalty Settings -->

      <!-- Commission Settings -->
      <div class="text-center mt-4">
        <button
          class="btn btn-gradient-primary btn-lg"
          @click="addAgent()"
        >
          Submit new agent
        </button>
      </div>
      <!-- End Commission Settings -->
    </div>
  </validation-observer>
</template>

<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'AddAgent',
  components: {
    ValidationProvider,
    ValidationObserver,
    // vSelect,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      username: '',
      status: 1,
      fristname: '',
      lastname: '',
      password: '',
      password_bk: '',
      upline: '',
      role: 0,
      hide: 0,
      web_name: '',
      amb_name: '',
      awc_name: '',
      sbo_name: '',
      power_id: '',
      lotto_id: '',
      sbo_id: '',
      sbo_serverid: '',
      db_group: '',
      percent: '',
      addition: 0.5,
      proOption: [],
      our_percent: '',
      hold_percent: '',
      roleActive: 0,
      activeBtn: 'GamesSlot',
      // items: [
      //   {
      //     ID: 'awc', our: 'awc_our', hold: 'awc_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'sbobet', our: 'sbobet_our', hold: 'sbobet_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'lotto', our: 'lotto_our', hold: 'lotto_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'PGSOFT2', our: 'pg_our', hold: 'pg_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'SLOTXO', our: 'slotxo_our', hold: 'slotxo_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'ASKMEBET', our: 'askmebet_our', hold: 'askmebet_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'HABANERO', our: 'habanero_our', hold: 'habanero_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'SPADE', our: 'spade_our', hold: 'spade_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'MICRO', our: 'micro_our', hold: 'micro_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'SIMPLEPLAY', our: 'simple_our', hold: 'simple_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'LIVE22', our: 'live22_our', hold: 'live22_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'GAMATRON', our: 'gamatron_our', hold: 'gamatron_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'AMBPOKER', our: 'ambpoker_our', hold: 'ambpoker_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'EVOPLAY', our: 'evoplay_our', hold: 'evoplay_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'CQ9', our: 'cq9_our', hold: 'cq9_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'CQ9V2', our: 'cq9_our', hold: 'cq9_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'JILI', our: 'jili_our', hold: 'jili_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'JOKER', our: 'joker123_our', hold: 'joker123_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'MANNA', our: 'mannaplay_our', hold: 'mannaplay_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'NETENT', our: 'netent_our', hold: 'netent_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'TIGERGAME', our: 'tigergame_our', hold: 'tigergame_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'FUNKY', our: 'funky_our', hold: 'funky_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'YGGDRASIL', our: 'yggdrasil_our', hold: 'yggdrasil_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'UPG', our: 'upg_our', hold: 'upg_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'GIOCO', our: 'gioco_our', hold: 'gioco_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'AMEBA', our: 'ameba_our', hold: 'ameba_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'AMBSLOT', our: 'ambslot_our', hold: 'ambslot_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'AMBSLOT2', our: 'ambslot_our', hold: 'ambslot_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'NETENT2', our: 'netent2_our', hold: 'netent2_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'RELAX', our: 'relax_our', hold: 'relax_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'SEXY', our: 'sexy_our', hold: 'sexy_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'SAGAME', our: 'sa_our', hold: 'sa_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'BIGGAME', our: 'biggame_our', hold: 'biggame_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'DREAM2', our: 'dream_our', hold: 'dream_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'WM', our: 'wm_our', hold: 'wm_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'BETGAME', our: 'betgame_our', hold: 'betgame_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'EBET', our: 'ebet_our', hold: 'ebet_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'ALLBET', our: 'allbet_our', hold: 'allbet_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'AGGAME', our: 'aggame_our', hold: 'aggame_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'PRETTY', our: 'pretty_our', hold: 'pretty_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'PRAGMATIC_LIVECASINO', our: 'pragmatic_casino_our', hold: 'pragmatic_casino_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'PRAGMATIC_SLOT', our: 'pragmatic_slot_our', hold: 'pragmatic_slot_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'XTREME', our: 'xtreme_our', hold: 'xtreme_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'KINGMAKER2', our: 'kingmaker_our', hold: 'kingmaker_hold', our_percent: 0, hold_percent: 0,
      //   },
      //   {
      //     ID: 'HOTGRAPH', our: 'hotgraph_our', hold: 'hotgraph_hold', our_percent: 0, hold_percent: 0,
      //   },
      // ],
      required,
      items: [],
    }
  },
  created() {
    this.GetPrefix()
    this.generateRange()
  },
  methods: {
    GetPrefix() {
      this.$http.get('https://reportapi.power100th.com/api/prefix/list')
        .then(res => {
          this.items = res.data
        })
    },
    async addAgent() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const obj = {
            status: this.status,
            role: this.role,
            hide: this.hide,
            upline: this.upline,
            power_id: this.power_id,
            sbo_id: this.sbo_id,
            lotto_id: this.lotto_id,
            db_group: this.db_group,
            username: this.username,
            fristname: this.fristname,
            lastname: this.lastname,
            password: this.password,
            web_name: this.web_name,
            amb_name: this.amb_name,
            awc_name: this.awc_name,
            sbo_name: this.sbo_name,
            sbo_serverid: this.sbo_serverid,
          }
          this.items.forEach(keyObj => {
            const {
              our, hold, our_percent, hold_percent,
            } = keyObj
            // eslint-disable-next-line camelcase
            obj[our] = Number(our_percent)
            // eslint-disable-next-line camelcase
            obj[hold] = Number(hold_percent)
          })
          try {
            await this.$http.post('https://reportapi.power100th.com/api/auth/register', obj)
            this.success()
            this.$router.push({ name: 'agent-list' })
          } catch (e) {
            console.log(e)
            this.error()
          }
        } else {
          this.error()
        }
      })
    },
    generateRange() {
      let value = 0.0
      while (value <= this.hold_percent) {
        this.proOption.push(value.toFixed(2))
        value += 0.5
      }
    },
    SelectDate(type) {
      this.activeBtn = type
    },
    switchRole(role) {
      this.role = role
    },
    switchStatus(status) {
      this.status = status
    },
    switchHide(hide) {
      this.hide = hide
    },
    success() {
      this.$swal({
        title: 'Success',
        text: 'Agent has been added successfully',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
    },
    error() {
      this.$swal({
        title: 'Error',
        text: 'Please fill in all required fields',
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    },
  },
}
</script>
<style>
label {
  font-size: 16px !important;
  font-weight: 500;
}
.col-form-label {
  font-size: 0.8rem;
  line-height: 1rem;
  color: #1f2532;
  padding-top: 0;
  padding-bottom: 0;
}
.form-group {
  margin-bottom: 0.8rem;
}

.btn-on {
  background-color: #0075b9;
  color: #fff;
}
.btn-r {
    border-top-right-radius: 0;
  border-bottom-right-radius: 0;
    border-right: transparent;
    border-left: #1f2532 solid 1px;
    border-top: #1f2532 solid 1px;
    border-bottom: #1f2532 solid 1px;
}
.btn-off {
  background-color: #fff;
  color: #1f2532;
}
.btn-l {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: transparent;
    border-right: #1f2532 solid 1px;
    border-top: #1f2532 solid 1px;
    border-bottom: #1f2532 solid 1px;
}
.btn-c {
    border-radius: 0;
    border-left: transparent;
    border-right: transparent;
    border-top: #1f2532 solid 1px;
    border-bottom: #1f2532 solid 1px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
